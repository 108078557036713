import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import Color from 'color'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      primaryLight: '#56B0BC',
      neutralLight1: '#B9B3AC',
      neutralLight2: '#E2DDD7',
      neutralLight3: '#EDE9E4',
      neutralLight4: '#FFFEFC',
      neutralLight5: '#FFFFFF',
      neutralDark1: '#000000',
      neutralDark2: '#363636',
      neutralDark3: '#383430',
      neutralDark4: '#69615B',
      danger: '#ED0F36',
    },
    getHoverColor: (color: string, strength = 0.1): string => {
      const c = Color(color)
      return c.darken(strength).hex()
    },
  },
  fontFamily: {
    heading: "'Italiana', serif",
    paragraph: "'Jost', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
