exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-article-page-index-tsx": () => import("./../../../src/templates/ArticlePage/index.tsx" /* webpackChunkName: "component---src-templates-article-page-index-tsx" */),
  "component---src-templates-breakfast-page-index-tsx": () => import("./../../../src/templates/BreakfastPage/index.tsx" /* webpackChunkName: "component---src-templates-breakfast-page-index-tsx" */),
  "component---src-templates-careers-page-index-tsx": () => import("./../../../src/templates/CareersPage/index.tsx" /* webpackChunkName: "component---src-templates-careers-page-index-tsx" */),
  "component---src-templates-concept-page-index-tsx": () => import("./../../../src/templates/ConceptPage/index.tsx" /* webpackChunkName: "component---src-templates-concept-page-index-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-day-spa-page-index-tsx": () => import("./../../../src/templates/DaySpaPage/index.tsx" /* webpackChunkName: "component---src-templates-day-spa-page-index-tsx" */),
  "component---src-templates-deal-page-index-tsx": () => import("./../../../src/templates/DealPage/index.tsx" /* webpackChunkName: "component---src-templates-deal-page-index-tsx" */),
  "component---src-templates-deals-page-index-tsx": () => import("./../../../src/templates/DealsPage/index.tsx" /* webpackChunkName: "component---src-templates-deals-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-infos-page-index-tsx": () => import("./../../../src/templates/InfosPage/index.tsx" /* webpackChunkName: "component---src-templates-infos-page-index-tsx" */),
  "component---src-templates-lounge-page-index-tsx": () => import("./../../../src/templates/LoungePage/index.tsx" /* webpackChunkName: "component---src-templates-lounge-page-index-tsx" */),
  "component---src-templates-magazine-page-index-tsx": () => import("./../../../src/templates/MagazinePage/index.tsx" /* webpackChunkName: "component---src-templates-magazine-page-index-tsx" */),
  "component---src-templates-natural-spa-page-index-tsx": () => import("./../../../src/templates/NaturalSpaPage/index.tsx" /* webpackChunkName: "component---src-templates-natural-spa-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-position-page-index-tsx": () => import("./../../../src/templates/PositionPage/index.tsx" /* webpackChunkName: "component---src-templates-position-page-index-tsx" */),
  "component---src-templates-restaurant-page-index-tsx": () => import("./../../../src/templates/RestaurantPage/index.tsx" /* webpackChunkName: "component---src-templates-restaurant-page-index-tsx" */),
  "component---src-templates-rooms-page-index-tsx": () => import("./../../../src/templates/RoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-rooms-page-index-tsx" */),
  "component---src-templates-suites-page-index-tsx": () => import("./../../../src/templates/SuitesPage/index.tsx" /* webpackChunkName: "component---src-templates-suites-page-index-tsx" */),
  "component---src-templates-treatments-page-index-tsx": () => import("./../../../src/templates/TreatmentsPage/index.tsx" /* webpackChunkName: "component---src-templates-treatments-page-index-tsx" */)
}

